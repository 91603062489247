@font-face{
  font-family: "Wavehaus";
  src: url('./assets/fonts/Wavehaus-158ExtraBold.otf');
}
@font-face{
  font-family: "Manrope";
  src: url('./assets/fonts/regular.otf');
  font-weight: normal;
}
@font-face{
  font-family: "Manrope";
  src: url('./assets/fonts/light.otf');
  font-weight: lighter;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body{
  height:100%;
  font-size: 16px;
  background-color: #1c1052;
}

img{
  max-width: 100%;
}

.navbar-default{
  border : 0;
  background-color: transparent;
  border-radius: 0;
  padding: 15px 20px;
}

.navbar-brand>.logo{
  width: 100px;
}

.navbar-right{
  margin-top: 15px;
}

.navbar-right>li>a{
  color: #22d5c3 !important;
  font-weight: bold;
  font-family: Manrope;
  font-size: 14px;
  text-align: center;
}

.navbar-default .navbar-toggle {
  border: none;
}

.navbar-default .navbar-toggle:focus, .navbar-default .navbar-toggle:hover {
  background-color: transparent;
}

.icon-bar{
  background-color: #22d5c3 !important;
}

.icon-bar:nth-child(2){
  width: 90%;
} 

.icon-bar:nth-child(3){
  width: 60%;
} 

.App{
  background-color: #1c1052;
  height:100vh;
}

.blog-name{
  color: #fff;
  font-family: Wavehaus;
  font-weight: bold;
  margin-top:20vh;
  font-size: 4em;
}

.contact-h1{
  color: #fff;
  font-family: Wavehaus;
  font-weight: bold;
  margin-top:1px;
  font-size: 2em;
}

input[type=file] {
  padding: 6px;
}

.contact-img{
  margin-top:10vh;
}

.about{
  font-family: "Manrope";
  color: #fff;
}

.start{
  color: #1c1052;
  background-color: #fff;
  font-family: "Manrope";
  text-decoration: none !important;
  font-weight: bold;
  padding:8px 12px; 
  border-radius: 5px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.2);
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
  border-color: #1c1052;
}

/* blog css */

.thumbnail{
  background-color: #241664;
  border:none;
  color:#fff;
  font-family: Manrope;
  padding: 10px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
}

h4{
  font-weight: bold;
  font-family: Manrope;
}

.social{
  color: #f6f8f8;
  font-weight: bold;
  font-family: Wavehaus;
}

small{
  font-size: 12px;
}

.cards>img{
  height: 225px;
}

.cards{
  height: 375px;
}

.read-more{
  background-color: #1c1052;
  color:#22d5c3;
  padding: 4px 10px;
  font-family: Manrope;
  border-radius: 4px;
}

/* blog post */
.article-img{
  display: block;
  margin: 0 auto;
}

.latest,.latest>a{
  list-style: none;
  margin:15px auto;
  color: #22d5c3;
}

h3{
  font-family: Wavehaus;
  color: #fff;
}

.loading{
  display: block;
  margin: 40vh auto;
}

.search-img{
  display: block;
  margin: 5px auto;
  width: 40%
}

.searchbar{
  background-color: #241664;
  border: none;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  padding:15px;
  font-family: manrope;
  color: #22d5c3 !important;
  margin-bottom: 25px;
}

::-webkit-input-placeholder { /* WebKit browsers */
  color: #22d5c3 !important;
  opacity: 0.5;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  color:#22d5c3 !important;
  opacity: 0.5;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
  color: #22d5c3 !important;
  opacity: 0.5;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
  color: #22d5c3 !important;
  opacity: 0.5;
}

label{
  color:#22d5c3;
  font-family: Manrope;
}

textarea{
  resize: vertical;
}

input[type="submit"]{
  background-color: #22d5c3;
  padding: 8px 15px;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  border-radius: 5px;
  color: #1c1052;
  border : none;
  font-weight: bold;
  font-family: manrope;
}

.loginform{
  margin-top: 20vh;
}

.loginbar{
  background-color: #241664;
  border: none;
  box-shadow: 5px 5px 25px 0 rgba(0,0,0,0.07);
  padding:15px;
  font-family: manrope;
  color: #22d5c3 !important;
  margin-bottom: 25px;
}

.resp-sharing-button__link,
.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 5px;
}

.resp-sharing-button {
  margin-top: 5px;
  border-radius: 50%;
  transition: 25ms ease-out;
  padding: 0.25em 0.5em;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button__icon svg {
  width: 1em;
  height: 1em;
  margin-right: 0.4em;
  vertical-align: top
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

/* Non solid icons get a stroke */
.resp-sharing-button__icon {
  stroke: #fff;
  fill: none
}

/* Solid icons get a fill */
.resp-sharing-button__icon--solid,
.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998;
}

.resp-sharing-button--facebook:hover,
.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373;
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee;
}

.resp-sharing-button--twitter:hover,
.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9;
}

.resp-sharing-button--whatsapp {
  background-color: #25D366;
  border-color: #25D366;
}

.resp-sharing-button--whatsapp:hover,
.resp-sharing-button--whatsapp:active {
  background-color: #1DA851;
  border-color: #1DA851;
}

.footer{
  background-color:#241664;
  color: #22d5c3;
  width: 100%;
  position: relative;
  display: none;
  bottom: 0;
  font-family: manrope;
  padding: 5px !important;
}

@media only screen and (max-width:768px){
  .navbar-default{
    padding: 5px;
  }
  .navbar-default .navbar-toggle {
    margin-top:25px;
  }
  .cards>img{
    height: auto;
  }
  
  .cards{
    height: auto;
  }
  .blog-name{
    margin-top:15px;
    font-size: 2.65em;
  }
  .contact-h1{
    margin-top: 25px;
    font-size: 2.15em;
  }
  .contact-img{
    margin-top:0
  }
  .about{
    padding-bottom: 10px;
  }
  .search-img{
    width: 80%;
    margin: 25px auto;
  }
  .loginform{
    margin: 5px auto;
  }
  .footer{
    display: block;
  }
}

@media only screen and (min-width:769px) and (max-width:991px){
  .hero-img{
    margin-top: 5vh;
  }
  .contact-h1,.blog-name{
    font-size: 5em;
    margin-top:25px
  }
  .about{
    margin-bottom: 25px;
  }
}

@media only screen and (min-width:992px) and (max-width:1199px){
  .hero-img{
    margin-top: 15vh;
  }
  .contact-h1{
    font-size: 4em;
  }
}

@media only screen and (min-width:1440px){
  .hero-img{
    margin-top:13vh;
  }
  .blog-name{
    margin-top:25vh;
    font-size: 4.5em;
  }
  .contact-h1{
    margin-top:10vh;
    font-size: 3.5em;
  }
  .contact-img{
    margin-top:16vh;
  }
}

@media only screen and (min-width:1920px){
  .container{
    width: 80%;
  }
  .contact-h1{
    margin-top:15vh;
    font-size: 4.25em;
  }
  body{
    font-size: 18px;
  }
  .about{
    font-size: 1.2em;
  }
  .hero{
    margin-top:20vh;
  }
  .loginform{
    margin-top:30vh
  }
}

@media only screen and (min-width:2560px){
  .logo{
    width: 200px !important;
  }
  .navbar-right>li>a{
    font-size: 24px !important; 
    margin-top: 20px;
  }
  .container{
    width: 80%;
  }
  .blog-name{
    font-size: 7em;
    margin-top: 30vh;
  }
  .hero-img{
    margin-top: 15vh 
  }
  .contact-h1{
    margin-top:20vh;
    font-size: 6em;
  }
  .contact-img{
    margin-top: 20vh;
  }
  body{
    font-size: 18px;
  }
  .about{
    font-size: 1.6em;
  }
  .cards{
    padding: 15px;
    height: 650px;
    font-size: 2em
  }
  .blog-post{
    font-size: 1.5em;
    margin-top:25px;
    padding: 15px;
  }
  .blog-post>img{
    width: 100%;
  }
  small{
    font-size: 0.5em;
  }
  h4{
    font-size: 1em;
  }
  .cards>img{
    height: 400px;
  }
  .hero{
    margin-top:20vh;
  }
  .loginform{
    margin-top:30vh
  }
}